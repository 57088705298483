import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { MonthPickerInput } from 'vue-month-picker'
import * as Sentry from '@sentry/vue'
import { isAxiosError } from 'axios'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/recaptcha'
import '@/libs/sweet-alerts'
import '@/libs/vue-mask'

// BSV Plugin Registration
Vue.use(MonthPickerInput)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

function addAxiosContext(event, error) {
  if (error.response) {
    const contexts = { ...event.contexts }
    contexts.Axios = { Response: error.response }
    // eslint-disable-next-line no-param-reassign
    event.contexts = contexts
  }
}
function addAxiosContextRecursive(event, error) {
  if (isAxiosError(error)) {
    addAxiosContext(event, error)
  } else if (error instanceof Error && error.cause) {
    addAxiosContextRecursive(event, error.cause)
  }
}
function handleSentryBeforeSend(event, hint) {
  addAxiosContextRecursive(event, hint?.originalException)
  return event
}

Sentry.init({
  beforeSend: handleSentryBeforeSend,
  Vue,
  dsn: 'https://385ccb3c23bf8fe4d828602716893394@o4508084032962560.ingest.us.sentry.io/4508084034600960',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // https://admin.imediato.online/
  // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
